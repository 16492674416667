// /src/App.tsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Commercial from './pages/Commercial';
import Contact from './pages/Contact';
import Film from './pages/Film';
import Home from './pages/Home';
import Tailoring from './pages/Tailoring';
import NotFound from './pages/NotFound';
import Header from './components/Header';

const App: React.FC = () => (
  <Router>
    {/* <Header /> */}
    <div id="container" className="container">
      <h1>Sabina Piccini</h1>
      <section id="content">
        <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/commercials" element={<Commercial />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/film" element={<Film />} />
          <Route path="/tailoring" element={<Tailoring />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </section>
  </div>
  </Router>
);

export default App;
