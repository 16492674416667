// /src/components/Pages/Home.tsx

import React from 'react';

const Home: React.FC = () => (
    <section className='bio'>
        <div>
            <div className='avatar'>
                <img src="images/sabinaPiccini.png" alt=""/>
            </div>
        </div>
        <div className='copy'> 
            <h2 className="jobDescription spacing"><em>London based stylist</em> and costume maker with a background in fashion styling.</h2>
            <div className="credits spacing">
                <h3>Recent credits</h3>
                <h4 className='creditName'>I Hate Suzie</h4>
                <span className='creditSubText'>Costume Buyer</span>
                <span className='creditSubText'>TV Series</span>
                <div className='video'>
                    <iframe width="280" height="158" src="https://www.youtube.com/embed/YEfLWmww7O0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                </div>
            </div>
            <div className="contact">
                <h3>Contact</h3>
                <p>Email: <a href="mailto:sabpiccstylist@gmail.com">sabpiccstylist@gmail.com</a></p>
            </div>
        </div>
    </section>
);

export default Home;