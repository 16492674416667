// /src/components/Pages/Commercial.tsx

import React from 'react';

const Commercial: React.FC = () => (
  <div className="yellow-background">
    <h1>Commercials</h1>

    <ol className="filmtv">
      <li>
          <h3>Aldi Hot Cross Buns</h3>
          <h4>Stylist Assistant, Another Film Company, 2016.</h4>
          <a href="#/">
              <img className="holderImage" src="images/filmtv/aldi.png" alt="" />
          </a>
          {/* <!--<figure>-->
              <!--<iframe width="400" height="225" frameborder="0" src="https://www.youtube.com/embed/STPso6qhbAQ" allowfullscreen></iframe>-->
          <!--</figure>--> */}
      </li><li>
          <h3>Walkers Choir of Champions, UEFA Champions League</h3>
          <h4>Stylist Assistant &amp; Standby, Partizan, 2016.</h4>
          <a href="#/">
              <img className="holderImage" src="images/filmtv/walkers.png" alt="" />
          </a>
          {/* <!--<figure>-->
              <!--<iframe width="400" height="225" frameborder="0" src="https://www.youtube.com/embed/4FbsbTCZ8os" allowfullscreen></iframe>-->
          <!--</figure>--> */}
      </li><li>
          <h3>Knorr</h3>
          <h4>Costume Designer, Fat Lemon Productions, 2015.</h4>
          <img className="holderImage" src="images/filmtv/knorr.jpg" alt="" />
      </li><li>
          <h3>Trident</h3>
          <h4>Stylist, MPC Creative, 2015.</h4>
          <img className="holderImage" src="images/filmtv/trident.gif" alt="" />
      </li><li>
          <h3>BBC FA Cup</h3>
          <h4>Stylist Assistant &amp; Standby, Red Bee Media, 2015.</h4>
          <a href="#/">
              <img className="holderImage" src="images/filmtv/facup.png" alt="" />
          </a>
          {/* <!--<figure>-->
              <!--<iframe width="400" height="225" frameborder="0" src="http://www.bbc.co.uk/programmes/p03cyqbr/player" allowfullscreen></iframe>-->
          <!--</figure>--> */}
      </li><li>
          <h3>Very.co.uk</h3>
          <h4>Stylist Assistant &amp; Standby, MPC Creative, 2015.</h4>
          <a href="#/">
              <img className="holderImage" src="images/filmtv/very.png" alt="" />
          </a>
          {/* <!--<figure>-->
              <!--<iframe width="400" height="225" src="https://www.youtube.com/embed/7H4N23RfY4s" frameborder="0" allowfullscreen></iframe>-->
          <!--</figure>--> */}
      </li><li>
          <h3>McDonald's</h3>
          <h4>Costume Maker, Stylist Asst &amp; Standby, Sonny London, 2015.</h4>
          <a href="#/">
              <img className="holderImage" src="images/filmtv/mcdonalds.png" alt="" />
          </a>
          {/* <!--<div className="special" style="width:400px; height:225px; position:relative; top:-2px;">-->
              <!--<div id="fb-root"></div><script>(function(d, s, id) {  var js, fjs = d.getElementsByTagName(s)[0];  if (d.getElementById(id)) return;  js = d.createElement(s); js.id = id;  js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.3";  fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'facebook-jssdk'));</script><div className="fb-video" data-allowfullscreen="1" data-href="/McDonaldsUK/videos/vb.209644529058679/1035983233091467/?type=3"></div>-->
          <!--</div>-->
          <!--<figure>-->
              <!--<iframe width="400" height="225" src="https://www.youtube.com/embed/XsdQBaS4XHQ" frameborder="0" allowfullscreen></iframe>-->
          <!--</figure>--> */}
      </li><li>
          <h3>Nandos</h3>
          <h4>Stylist Assistant &amp; Standby, Moxie Pictures, 2015.</h4>
          <a href="#/">
              <img className="holderImage" src="images/filmtv/nandos.png" alt="" />
          </a>
          {/* <!--<figure>-->
              <!--<iframe width="400" height="225" src="https://www.youtube.com/embed/NYFETdCy4RY" frameborder="0" allowfullscreen></iframe>-->
          <!--</figure>--> */}
      </li><li>
          <h3>WestJet</h3>
          <h4>Costume Maker, Stylist Asst &amp; Standby, Moxie Pictures, 2015.</h4>
          <a href="#/">
              <img className="holderImage" src="images/filmtv/davethedog.png" alt="" />
          </a>
          {/* <!--<figure>-->
              <!--<iframe width="400" height="225" src="https://www.youtube.com/embed/KyvB85rdKsY" frameborder="0" allowfullscreen></iframe>-->
          <!--</figure>--> */}
      </li><li>
          <h3>Nationwide</h3>
          <h4>Stylist Assistant &amp; Standby, Knucklehead, 2015.</h4>
          <img className="projectImage" src="images/filmtv/nationwide.jpg" alt="" />
      </li><li>
          <h3>Nationwide "Real Life" Idents</h3>
          <h4>Stylist Assistant &amp; Standby, Agile Films, 2015.</h4>
          <a href="#/">
              <img className="holderImage" src="images/filmtv/nationwide2.png" alt="" />
          </a>
          {/* <!--<figure>-->
              <!--<iframe src="https://player.vimeo.com/video/124417544?title=0&byline=0&portrait=0" width="400" height="225" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>-->
          <!--</figure>--> */}
      </li><li>
          <h3>Sainsbury's</h3>
          <h4>Stylist Assistant &amp; Standby, Moxie Pictures, 2015.</h4>
      <a href="#/">
          <img className="holderImage" src="images/filmtv/sainsburys.png" alt="" />
      </a>
          {/* <!--<figure>-->
              <!--<iframe width="400" height="225" src="https://www.youtube.com/embed/Oux8JV_5mNk" frameborder="0" allowfullscreen></iframe>-->
          <!--</figure>--> */}
      </li><li>
          <h3>Dropbox</h3>
          <h4>Stylist, Nexus, 2015.</h4>
          <a href="#/">
              <img className="holderImage" src="images/filmtv/dropbox.png" alt="" />
          </a>
          {/* <!--<figure>-->
              <!--<iframe src="https://player.vimeo.com/video/142497378?title=0&byline=0&portrait=0" width="400" height="225" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>-->
          <!--</figure>--> */}
      </li>
  </ol>
  </div>
);

export default Commercial;