// /src/components/Pages/Film.tsx

import React from 'react';

const Film: React.FC = () => (
  <>
      <h1>Film Work</h1>
      <ol className="filmtv">
              <li>
                  <h3>The Killing Drink</h3>
                  <h4>Costume Designer, A Lauris Beinerts Production, 2015.</h4>
                  <a href="#/">
                      <img className="holderImage" src="images/filmtv/theKillingDrink.png" alt="" />
                  </a>
                  {/* <!--<figure>-->
                      <!--<iframe width="400" height="225" src="https://www.youtube.com/embed/ZhQ2j2f1BvU" frameborder="0" allowfullscreen></iframe>-->
                  <!--</figure>--> */}
              </li><li>
                  <h3>2 Hours</h3>
                  <h4>Standby, Makelight Productions, 2015.</h4>
                  <img className="holderImage" src="images/holderImage.gif" alt="" />
              </li><li>
                  <h3>Let's Be Frank (Fight Scene)</h3>
                  <h4>Costume Designer, A Peter Hamblin Production, 2015.</h4>
                  <img className="holderImage" src="images/holderImage.gif" alt="" />
              </li><li>
                  <h3>The Pyramid Texts</h3>
                  <h4>Costume Standby, Volatile Productions, 2014.</h4>
                  <a href="#/">
                      <img className="holderImage" src="images/filmtv/thePyramidTexts.png" alt="" />
                  </a>
                  {/* <!--<figure>-->
                      <!--<iframe width="400" height="225" src="https://www.youtube.com/embed/hj4XWZ3xp-c" frameborder="0" allowfullscreen></iframe>-->
                  <!--</figure>--> */}
              </li><li>
                  <h3>Between Places</h3>
                  <h4>Costume Assistant &amp; Standby, 2013.</h4>
                  <a href="#/">
                      <img className="holderImage" src="images/filmtv/betweenPlaces.png" alt="" />
                  </a>
                  {/* <!--<figure>-->
                      <!--<iframe width="400" height="225" src="https://www.youtube.com/embed/2srixOvfaW4" frameborder="0" allowfullscreen></iframe>-->
                  <!--</figure>--> */}
              </li><li>
                  <h3>The First Musketeer</h3>
                  <h4>Costume Maker, 2013.</h4>
                  <a href="#/">
                      <img className="holderImage" src="images/filmtv/firstMusketeer.png" alt="" />
                  </a>
                  {/* <!--<figure>-->
                      <!--<iframe width="400" height="225" src="//www.youtube.com/embed/5Kw_2YPw_b0" frameborder="0" allowfullscreen></iframe>-->
                  <!--</figure>--> */}
              </li>
          </ol>
  </>
);

export default Film;