// /src/components/Pages/Tailoring.tsx

import React from 'react';

const Tailoring: React.FC = () => (
  <div className="yellow-background">
    <h1>Tailoring</h1>

    <h3>London 2012, Olympic &amp; Paralympic Ceremonies</h3>
        <ul id="gallery_2012" className="clearfix gallery">
            <li ng-repeat="image in olImages">
                <a ng-click="openLightboxModal1($index)">
                    <img src="/images/galleries/olympics/olympics2.jpg" title="London 2012, Olympics" alt="London 2012, Olympics" />
                    <img src="/images/galleries/olympics/olympics3.jpg" title="London 2012, Olympics" alt="London 2012, Olympics" />
                    <img src="/images/galleries/olympics/olympics4.jpg" title="London 2012, Olympics" alt="London 2012, Olympics" />
                    <img src="/images/galleries/olympics/olympics5.jpg" title="London 2012, Olympics" alt="London 2012, Olympics" />
                    <img src="/images/galleries/olympics/olympics6.jpg" title="London 2012, Olympics" alt="London 2012, Olympics" />
                </a>
            </li>
        </ul>
        <h4>
            <span className="">My Role:</span>Costume Maker, Dresser, Wardrobe Maintainance &amp; Alterations<br/>
            <span className="">Designers:</span> Suttirat Anne Larlarb, Michael Sharp, Es Devlin, Mark Tildesley, Moritz Junge<br/>
            <span className="">Artistic Directors:</span> Danny Boyle, Kim Gavin, Bradley Hemmings, Jenny Sealey
        </h4>

        <h3>National Theatre Tailoring Department</h3>
        <ul id="gallery_2014c" className="clearfix gallery">
            <li ng-repeat="image in ntImages">
                <a ng-click="openLightboxModal2($index)">
                    <img src="/images/galleries/national_theatre/1.png" title="" alt="" />
                    <img src="/images/galleries/national_theatre/3.png" title="" alt="" />
                    <img src="/images/galleries/national_theatre/4.png" title="" alt="" />
                    <img src="/images/galleries/national_theatre/6.png" title="" alt="" />
                </a>
            </li>
        </ul>
        <h4></h4>

        <h3>Waistcoat &amp; Trousers</h3>
        <ul id="gallery_2014a" className="clearfix gallery">
            <li ng-repeat="image in wtImages">
                <a ng-click="openLightboxModal3($index)">
                    <img src="/images/galleries/waistcoat_trousers/thumb1.png" title="" alt="" />
                    <img src="/images/galleries/waistcoat_trousers/thumb2.png" title="" alt="" />
                    <img src="/images/galleries/waistcoat_trousers/thumb3.png" title="" alt="" />
                    <img src="/images/galleries/waistcoat_trousers/thumb4.png" title="" alt="" />
                    <img src="/images/galleries/waistcoat_trousers/thumb5.png" title="" alt="" />
                    <img src="/images/galleries/waistcoat_trousers/thumb6.png" title="" alt="" />
                    <img src="/images/galleries/waistcoat_trousers/thumb7.png" title="" alt="" />
                </a>
            </li>
        </ul>
        <h4></h4>

        <h3>Ladies Tailoring</h3>
        <ul id="gallery_2014b" className="clearfix gallery">
            <li ng-repeat="image in ltImages">
                <a ng-click="openLightboxModal4($index)">
                    <img src="/images/galleries/womans_tailoring/thumb1.png" title="" alt="" />
                    <img src="/images/galleries/womans_tailoring/thumb2.png" title="" alt="" />
                    <img src="/images/galleries/womans_tailoring/thumb3.png" title="" alt="" />
                    <img src="/images/galleries/womans_tailoring/thumb4.png" title="" alt="" />
                    <img src="/images/galleries/womans_tailoring/thumb5.png" title="" alt="" />
                    <img src="/images/galleries/womans_tailoring/thumb6.png" title="" alt="" />
                </a>
            </li>
        </ul>
        <h4></h4>

        <h3>Tudor Garments</h3>
        <ul id="gallery_tudorproj" className="clearfix gallery">
            <li ng-repeat="image in tgImages">
                <a ng-click="openLightboxModal5($index)">
                    <img src="/images/galleries/tudor_project/1.png" title="" alt="" />
                    <img src="/images/galleries/tudor_project/2.png" title="" alt="" />
                    <img src="/images/galleries/tudor_project/3.png" title="" alt="" />
                    <img src="/images/galleries/tudor_project/4.png" title="" alt="" />
                    <img src="/images/galleries/tudor_project/5.png" title="" alt="" />
                    <img src="/images/galleries/tudor_project/6.png" title="" alt="" />
                    {/* <img src="/images/galleries/tudor_project/7.png" title="" alt="" /> */}
                    {/* <img src="/images/galleries/tudor_project/8.png" title="" alt="" /> */}
                    {/* <img src="/images/galleries/tudor_project/9.png" title="" alt="" /> */}
                </a>
            </li>
        </ul>
        <h4></h4>

        <h3>Corsetry</h3>
        <ul id="gallery_corsetry" className="clearfix gallery">
            <li ng-repeat="image in ctImages">
                <a ng-click="openLightboxModal6($index)">
                    <img src="/images/galleries/corsetry/thumb1.png" title="" alt="" />
                    <img src="/images/galleries/corsetry/thumb2.png" title="" alt="" />
                    <img src="/images/galleries/corsetry/thumb3.png" title="" alt="" />
                    <img src="/images/galleries/corsetry/thumb4.png" title="" alt="" />
                    <img src="/images/galleries/corsetry/thumb5.png" title="" alt="" />
                    <img src="/images/galleries/corsetry/thumb6.png" title="" alt="" />
                </a>
            </li>
        </ul>
        <h4></h4>

        <h3>Peter Pan, The Minack Theatre, Cornwall</h3>
        <ul id="gallery_pan" className="clearfix gallery">
            <li ng-repeat="image in ppImages">
                <a ng-click="openLightboxModal7($index)">
                    <img src="/images/galleries/peter_pan/pan1thumb.jpg" title="" alt="" />
                    <img src="/images/galleries/peter_pan/pan2thumb.jpg" title="" alt="" />
                    <img src="/images/galleries/peter_pan/pan3thumb.jpg" title="" alt="" />
                    <img src="/images/galleries/peter_pan/pan4thumb.jpg" title="" alt="" />
                    <img src="/images/galleries/peter_pan/pan5thumb.jpg" title="" alt="" />
                    <img src="/images/galleries/peter_pan/pan6thumb.jpg" title="" alt="" />
                    <img src="/images/galleries/peter_pan/pan7thumb.jpg" title="" alt="" />
                </a>
            </li>
        </ul>
        <h4><span className="short">My Role:</span>Costume Maker<br/><span className="short">Designer:</span> Keith Orton <br/><span className="short">Director:</span> Ray Downing </h4>

        <h3>Shakespeare In Shoreditch, Hackney Empire</h3>
        <ul id="gallery_2" className="clearfix gallery">
            <li ng-repeat="image in ssImages">
                <a ng-click="openLightboxModal8($index)">
                    <img src="/images/galleries/shakespeare_shoreditch/thumb1.jpg" title="" alt="" />
                    <img src="/images/galleries/shakespeare_shoreditch/thumb2.jpg" title="" alt="" />
                    <img src="/images/galleries/shakespeare_shoreditch/thumb3.jpg" title="" alt="" />
                    <img src="/images/galleries/shakespeare_shoreditch/thumb4.jpg" title="" alt="" />
                    <img src="/images/galleries/shakespeare_shoreditch/thumb5.jpg" title="" alt="" />
                    <img src="/images/galleries/shakespeare_shoreditch/thumb6.jpg" title="" alt="" />
                    <img src="/images/galleries/shakespeare_shoreditch/thumb7.jpg" title="" alt="" />
                    <img src="/images/galleries/shakespeare_shoreditch/thumb8.jpg" title="" alt="" />
                </a>
            </li>
        </ul>
        <h4><span className="short">My Role:</span>Costume Maker / Designer<br/> <span className="short">Director:</span> Wendy Richardson</h4>

        <h3>A Christmas Carol, Tableau Vivant, CSSD</h3>
        <ul id="gallery" className="clearfix gallery">
            <li ng-repeat="image in ccImages">
                <a ng-click="openLightboxModal9($index)">
                    <img src="/images/galleries/christmas_carol/thumb1.png" title="" alt="" />
                    <img src="/images/galleries/christmas_carol/thumb2.png" title="" alt="" />
                    {/* <img src="/images/galleries/christmas_carol/thumb3.png" title="" alt="" /> */}
                    <img src="/images/galleries/christmas_carol/thumb4.png" title="" alt="" />
                </a>
            </li>
        </ul>
        <h4><span className="short">My Role:</span>Costume Maker<br/><span className="short">Designer:</span> Keith Orton <br/><span className="short">Director:</span> Keith Orton </h4>

        {/* <h3>Graduation Exhibition</h3>
        <ul id="gallery_exhibition" className="clearfix gallery">
            <li ng-repeat="image in geImages">
                <a ng-click="openLightboxModal10($index)">
                    <img ng-src="{{image.thumbUrl}}" title="" alt="" />
                </a>
            </li>
        </ul> */}
        <h4><span className="short">My Role:</span>Costume Maker / Designer</h4>
  </div>
);

export default Tailoring;