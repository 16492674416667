// /src/components/Pages/NotFound.tsx

import React from 'react';

const NotFound: React.FC = () => (
  <div className="yellow-background">
    <h1>404 - Page not found</h1>
  </div>
);

export default NotFound;