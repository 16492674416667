// /src/components/Pages/Contact.tsx

import React from 'react';

const Contact: React.FC = () => (
  <div className="yellow-background">
    <h1>Contact Page</h1>
    <p>Coming soon...</p>
  </div>
);

export default Contact;